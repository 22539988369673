import React, { ComponentType, useState, useRef, useCallback } from 'react';

import userStatusSelectOpenButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_open_button_click';
import userStatusSelectOpenElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_open_element_shown';
import { Card, Cell, CellText } from '@hh.ru/magritte-ui';
import { ChevronDownOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { JobSearchStatus as JobSearchStatusType, JobSearchStatusForm } from 'lux/models/applicantUserStatuses';
import ElementShownAnchor from 'src/components/ElementShownAnchor';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import JobSearchStatusContainer from 'src/pages/ApplicantResumes/components/redesign/JobSearchStatus/components/JobSearchStatusContainer';
import JobSearchStatusOptions from 'src/pages/ApplicantResumes/components/redesign/JobSearchStatus/components/JobSearchStatusOptions';

const SELECT_LIST_NAME = JobSearchStatusForm.MyResumesDesktop;

const TrlKeys = {
    subtitle: 'jobSearchStatus.searchStatusText.v2',
    status: {
        [JobSearchStatusType.Unknown]: 'jobSearchStatus.current.unknown',
        [JobSearchStatusType.ActiveSearch]: 'jobSearchStatus.current.version2.active_search',
        [JobSearchStatusType.LookingForOffers]: 'jobSearchStatus.current.version2.looking_for_offers',
        [JobSearchStatusType.HasJobOffer]: 'jobSearchStatus.current.version2.has_job_offer',
        [JobSearchStatusType.AcceptedJobOffer]: 'jobSearchStatus.current.version2.accepted_job_offer',
        [JobSearchStatusType.NotLookingForJob]: 'jobSearchStatus.current.version2.not_looking_for_job',
    },
};

const JobSearchStatus: ComponentType = translation(({ trls }) => {
    const topLevelDomain = useSelector(({ topLevelDomain }) => topLevelDomain);
    const userStatuses = useSelector(({ applicantUserStatuses }) => applicantUserStatuses);
    const queryParamSetStatus = useSelector(({ router }) => router.location.query.set_job_search_status);

    const [showForm, setShowForm] = useState(!!queryParamSetStatus);
    const currentStatus = userStatuses?.jobSearchStatus?.name || JobSearchStatusType.Unknown;
    const [newStatus, setNewStatus] = useState(currentStatus);

    const activatorRef = useRef(null);

    const handleCardClick = () => {
        setShowForm(!showForm);
        userStatusSelectOpenButtonClick();
    };

    const handleClose = useCallback(() => {
        setShowForm(false);
    }, []);

    const setStatus = useCallback(
        (status: JobSearchStatusType) => {
            setNewStatus(status);
            handleClose();
        },
        [handleClose]
    );

    const resetStatus = useCallback(() => {
        setStatus(currentStatus);
    }, [currentStatus, setStatus]);

    const subtitle = trls[TrlKeys.subtitle].replace('{0}', topLevelDomain as string);

    return (
        <ElementShownAnchor fn={(element) => userStatusSelectOpenElementShown(element)}>
            <Card
                stretched
                padding={24}
                borderRadius={24}
                onClick={handleCardClick}
                actionCard
                showBorder
                ref={activatorRef}
                data-qa="job-search-status-change-link"
            >
                <Cell right={<ChevronDownOutlinedSize24 />}>
                    <CellText type="subtitle">{subtitle}</CellText>
                    <CellText data-qa={`job-search-status-current_${newStatus}`}>
                        {trls[TrlKeys.status[newStatus]]}
                    </CellText>
                </Cell>
                <JobSearchStatusContainer visible={showForm} onClose={handleClose} activatorRef={activatorRef}>
                    <JobSearchStatusOptions
                        id={SELECT_LIST_NAME}
                        status={newStatus}
                        setStatus={setStatus}
                        resetStatus={resetStatus}
                    />
                </JobSearchStatusContainer>
            </Card>
        </ElementShownAnchor>
    );
});

export default JobSearchStatus;
