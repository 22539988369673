/* eslint-disable import/prefer-default-export */
import isValidKey from 'bloko/common/isValidKey';

import { Recommendations } from 'lux/models/resume/resumeCommon.types';
import { ApplicantServices } from 'src/components/ApplicantServices/common';
import { useSelector } from 'src/hooks/useSelector';

export const RECOMMENDATIONS_LINKS_MAP = {
    [Recommendations.EditResume]: '/expert_resume?from=resume_recommendation',
    [Recommendations.UpdateResume]: '/applicant/services/findjob?from=resume_recommendation',
    [Recommendations.ChangeVisibility]: '/article/98?from=resume_recommendation',
} as const;

const RECOMMENDATION_TO_SERVICE_MAP = {
    [Recommendations.EditResume]: ApplicantServices.CompleteResume,
    [Recommendations.UpdateResume]: ApplicantServices.ResumeRenewal,
    [Recommendations.ChangeVisibility]: ApplicantServices.ResumeTargetEmployer,
} as const;

export const useRecommendationLink = (recommendation: Recommendations): null | string => {
    const applicantPaymentServices = useSelector((state) => state.applicantPaymentServices);

    if (!isValidKey(RECOMMENDATIONS_LINKS_MAP, recommendation)) {
        return null;
    }

    if (applicantPaymentServices.includes(RECOMMENDATION_TO_SERVICE_MAP[recommendation])) {
        return RECOMMENDATIONS_LINKS_MAP[recommendation];
    }

    return null;
};
