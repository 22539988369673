import { FC, Fragment } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import { useSelector } from 'src/hooks/useSelector';

import { FindJobPromo } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/FindJobPromo';
import { ResumeCard } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeCard';
import { useFindJobPromoEnabled } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/hooks/useFindJobPromoEnabled';

const ApplicantResumes: FC = () => {
    const latestResumeHash = useSelector((state) => state.latestResumeHash);
    const publishedResumes = useSelector(({ applicantResumes }) =>
        applicantResumes.filter(({ _attributes }) => _attributes.isSearchable)
    );
    const unpublishedResumes = useSelector(({ applicantResumes }) =>
        applicantResumes.filter(({ _attributes }) => !_attributes.isSearchable)
    );

    const hasCodePracticeBanner = useSelector((state) => !!state.microFrontends.codePracticeBanner);
    const hasCareerPlatformBanner = useSelector(
        (state) => !!Object.keys(state.microFrontends).find((key) => key.startsWith('careerPlatformBannerByResumeHash'))
    );

    const showFindJobPromo = useFindJobPromoEnabled();

    return (
        <VSpacingContainer default={16} gteS={24}>
            {publishedResumes.concat(unpublishedResumes).map((resume, index, resumeList) => (
                <Fragment key={resume._attributes.id}>
                    <ResumeCard resume={resume} />
                    {showFindJobPromo && index === 0 && <FindJobPromo />}
                    {latestResumeHash === resume._attributes.hash &&
                        hasCareerPlatformBanner &&
                        !(hasCodePracticeBanner && resumeList.length - 1 === index) && (
                            <>
                                <ContainerForMicroFrontend
                                    place={`careerPlatformBannerByResumeHash-${latestResumeHash}`}
                                />
                            </>
                        )}
                </Fragment>
            ))}
        </VSpacingContainer>
    );
};

export default ApplicantResumes;
