import React, { useMemo, ReactElement } from 'react';

import { ScrollableContainer, ScrollableItem, HSpacingContainer, useBreakpoint } from '@hh.ru/magritte-ui';
import {
    EnvelopeOpenedOutlinedSize24,
    VideocameraOutlinedSize24,
    BubbleRoundDoubleOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';

import { PaymentServicesFromBackend } from 'lux/models/applicantPaymentServices';
import translation from 'src/components/translation';
import { useIsRussia } from 'src/hooks/useCountries';
import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';

import ApplicantServicesCard from './ApplicantServicesCard';

import styles from './styles.less';

interface ApplicantService {
    id: string | number;
    title: string;
    text: string;
    link: string;
    icon: ReactElement;
    dataAnalytics: string;
    enabled: boolean;
}

const TrlKeys = {
    completeResumeTitle: 'applicantServicesSlider.cards.completeResume.title.v2',
    completeResumeText: 'applicantServicesSlider.cards.completeResume.text.v2',

    interviewTitle: 'applicantServicesSlider.cards.interview.title',
    interviewText: 'applicantServicesSlider.cards.interview.text',

    consultationTitle: 'applicantServicesSlider.cards.consultation.title',
    consultationText: 'applicantServicesSlider.cards.consultation.text.v2',

    extraBannerTitle: 'applicantServicesSlider.cards.extraBanner.title',
    extraBannerText: 'applicantServicesSlider.cards.extraBanner.text',
};

const EXTRA_BANNER_ID = 'extra_banner';

const ApplicantServicesSlider = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const isHeadHunterPlatform = useIsHeadHunterPlatform();

    const applicantPaymentServices = useSelector(({ applicantPaymentServices }) => applicantPaymentServices);
    const resumeExtraBannerLink = useSelector((state) => state.resumeExtraBannerLink);
    const isRussia = useIsRussia();
    const isHHRu = useIsHhru();

    const applicantServices = useMemo(() => {
        const services: ApplicantService[] = [];

        if (isHeadHunterPlatform) {
            services.push(
                {
                    id: EXTRA_BANNER_ID,
                    title: trls[TrlKeys.extraBannerTitle],
                    text: trls[TrlKeys.extraBannerText],
                    icon: <div className={styles.resumeExtraBannerIcon} />,
                    link: resumeExtraBannerLink,
                    dataAnalytics: 'resume-extra-banner',
                    enabled: !!resumeExtraBannerLink && isRussia && isHHRu,
                },
                {
                    id: 'complete_resume',
                    title: trls[TrlKeys.completeResumeTitle],
                    text: trls[TrlKeys.completeResumeText],
                    icon: <EnvelopeOpenedOutlinedSize24 initial="accent" />,
                    link: '/article/expert_resume',
                    dataAnalytics: 'complete_resume',
                    enabled: applicantPaymentServices.includes(PaymentServicesFromBackend.CompleteResume),
                },
                {
                    id: 'interview_practice',
                    title: trls[TrlKeys.interviewTitle],
                    text: trls[TrlKeys.interviewText],
                    icon: <VideocameraOutlinedSize24 initial="warning-secondary" />,
                    link: '/interview_practice',
                    dataAnalytics: 'interview_practice',
                    enabled: applicantPaymentServices.includes(
                        PaymentServicesFromBackend.CompleteResumeInterviewPractice
                    ),
                },
                {
                    id: 'career_consult',
                    title: trls[TrlKeys.consultationTitle],
                    text: trls[TrlKeys.consultationText],
                    icon: <BubbleRoundDoubleOutlinedSize24 initial="positive" />,
                    link: '/career_consult',
                    dataAnalytics: 'career_consult',
                    enabled: applicantPaymentServices.includes(PaymentServicesFromBackend.CompleteResumeConsultOnly),
                }
            );
        }

        return services.filter((service) => service.enabled);
    }, [isHeadHunterPlatform, trls, resumeExtraBannerLink, isRussia, isHHRu, applicantPaymentServices]);

    if (isMobile) {
        return null;
    }

    return applicantServices.length ? (
        <ScrollableContainer correction={0}>
            <HSpacingContainer default={12}>
                {applicantServices.map((service) => (
                    <ScrollableItem key={service.id}>
                        <div className={styles.cardWrapper}>
                            {service.id === EXTRA_BANNER_ID && <div className={styles.resumeExtraBannerIconBg} />}
                            <ApplicantServicesCard
                                title={service.title}
                                text={service.text}
                                icon={service.icon}
                                link={service.link}
                                dataAnalytics={service.dataAnalytics}
                            />
                        </div>
                    </ScrollableItem>
                ))}
            </HSpacingContainer>
        </ScrollableContainer>
    ) : null;
});

export default ApplicantServicesSlider;
