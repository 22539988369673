import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import userStatusSelectOpenButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_open_button_click';
import userStatusSelectOpenElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/user_status_select_open_element_shown';
import Button, { ButtonKind, ButtonScale, ButtonType } from 'bloko/blocks/button';
import DropDown from 'bloko/blocks/drop/Down';
import NoPaddings from 'bloko/blocks/drop/Down/NoPaddings';
import { DropPlacement } from 'bloko/blocks/drop/constants';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { JobSearchStatus as JobSearchStatusType, JobSearchStatusForm } from 'lux/models/applicantUserStatuses';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import ChangeForm from 'src/components/Applicant/JobSearchStatus/ChangeForm';
import MobileModal from 'src/components/Applicant/JobSearchStatus/MobileModal';

import styles from './job-search-status.less';

const CHANGE_FORM_NAME = JobSearchStatusForm.MyResumesDesktop;

const TrlKeys = {
    save: 'jobSearchStatus.save',
    change: 'jobSearchStatus.change',
    status: {
        [JobSearchStatusType.Unknown]: 'jobSearchStatus.current.unknown',
        [JobSearchStatusType.ActiveSearch]: 'jobSearchStatus.current.version2.active_search',
        [JobSearchStatusType.LookingForOffers]: 'jobSearchStatus.current.version2.looking_for_offers',
        [JobSearchStatusType.HasJobOffer]: 'jobSearchStatus.current.version2.has_job_offer',
        [JobSearchStatusType.AcceptedJobOffer]: 'jobSearchStatus.current.version2.accepted_job_offer',
        [JobSearchStatusType.NotLookingForJob]: 'jobSearchStatus.current.version2.not_looking_for_job',
    },
};

const JobSearchStatus: TranslatedComponent = ({ trls }) => {
    const userStatuses = useSelector((state) => state.applicantUserStatuses);
    const currentStatus = userStatuses?.jobSearchStatus?.name || JobSearchStatusType.Unknown;
    const queryParamSetStatus = useSelector((state) => state.router.location.query.set_job_search_status);
    const [showForm, setShowForm] = useState(!!queryParamSetStatus);
    const onClose = () => setShowForm(false);
    const isXs = useBreakpoint() === Breakpoint.XS;
    const linkRef = useRef(null);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (linkRef.current) {
            userStatusSelectOpenElementShown(linkRef.current);
        }
    }, []);

    useEffect(() => {
        if (showForm) {
            setIsDisabled(true);
        }
    }, [showForm]);

    const onShowLinkClick = () => {
        setShowForm(!showForm);
        userStatusSelectOpenButtonClick();
    };

    const renderDropForm = () => {
        return (
            <NoPaddings>
                <div className={styles.formWrapper}>
                    <ChangeForm
                        currentStatus={currentStatus}
                        onSuccess={onClose}
                        formId={CHANGE_FORM_NAME}
                        setDisabled={setIsDisabled}
                    />
                    <div className={styles.formFooter}>
                        <Button
                            type={ButtonType.Submit}
                            kind={ButtonKind.Primary}
                            scale={ButtonScale.Small}
                            form={CHANGE_FORM_NAME}
                            disabled={isDisabled}
                            data-qa="job-search-status-change-confirm"
                        >
                            {trls[TrlKeys.save]}
                        </Button>
                    </div>
                </div>
            </NoPaddings>
        );
    };

    return (
        <>
            <div className={styles.wrapper}>
                <div
                    className={classnames(styles.indicator, {
                        [styles.indicatorLooking]: [
                            JobSearchStatusType.ActiveSearch,
                            JobSearchStatusType.LookingForOffers,
                            JobSearchStatusType.HasJobOffer,
                        ].includes(currentStatus),
                        [styles.indicatorNotLooking]: [
                            JobSearchStatusType.AcceptedJobOffer,
                            JobSearchStatusType.NotLookingForJob,
                        ].includes(currentStatus),
                    })}
                />
                <div className={styles.status} data-qa={`job-search-status-current_${currentStatus}`}>
                    {trls[TrlKeys.status[currentStatus]]}
                </div>
                <div className={styles.change}>
                    <DropDown
                        show={!isXs && showForm}
                        placement={DropPlacement.BottomStart}
                        onClose={onClose}
                        render={renderDropForm}
                    >
                        <div className={styles.changeInner}>
                            <Link
                                appearance={LinkAppearance.Pseudo}
                                onClick={onShowLinkClick}
                                ref={linkRef}
                                data-qa="job-search-status-change-link"
                            >
                                {trls[TrlKeys.change]}
                            </Link>
                        </div>
                    </DropDown>
                </div>
                <MobileModal visible={isXs && showForm} onClose={onClose} currentStatus={currentStatus} />
            </div>
            <VSpacing base={7} />
        </>
    );
};

export default translation(JobSearchStatus);
