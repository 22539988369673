import React, { useCallback, useMemo, useState } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import verificationKeySkillBannerElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/verification_key_skill/verification_key_skill_banner_element_shown';
import verificationKeySkillToTestButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/verification_key_skill/verification_key_skill_to_test_button_click';
import verificationKeySkillViewAllButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/verification_key_skill/verification_key_skill_view_all_button_click';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind, ButtonScale } from 'bloko/blocks/button';
import { H3Section } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import {
    ApplicantSkillsVerificationMethod,
    ApplicantSkillsVerificationMethodKeys,
} from 'lux/models/applicantSkillsVerificationMethods';
import ApplicantSkillsVerificationMethodModal from 'src/components/ApplicantSkillsVerificationMethodModal/ApplicantSkillsVerificationMethodModal';
import useApplicantSkillsVerificationMethodModal from 'src/components/ApplicantSkillsVerificationMethodModal/useApplicantSkillsVerificationMethodModal';
import OutdatedSkill from 'src/components/OutdatedSkill';
import { getTagName } from 'src/components/getTagName';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useSkillVerificationMethodsBreadcrumbs from 'src/hooks/useSkillVerificationMethodsBreadcrumbs';

import ApplicantSkillsVerificationMethodsCardButton from 'src/components/ResumeView/ApplicantSkillsVerificationMethodsCardButton';
import { HhtmSource } from 'src/components/ResumeView/HtmlSource';

interface ApplicantSkillsVerificationMethodsCardProps {
    applicantSkillsVerificationMethods: ApplicantSkillsVerificationMethod[];
    applicantSkillsVerificationExpiring: ApplicantSkillsVerificationMethod[];
}

const NUMBER_METHODS = {
    MIN_SHOWN_METHODS: 0,
    MAX_SHOWN_METHODS: 5,
};

const TrlKeys = {
    hide: 'applicantSkillsVerificationMethods.hide',
    showAll: 'applicantSkillsVerificationMethods.showAll',
    title: 'applicantSkillsVerificationMethods.title',
    subTitle: 'applicantSkillsVerificationMethods.subTitle',
    subTitleNotification: 'applicantSkillsVerificationMethods.subTitle.notification',
    skills: 'applicantSkillsVerificationMethods.skills',
    link: 'applicantSkillsVerificationMethods.link',
    keySkills: 'applicantSkillsVerificationMethods.keyskills',
    languages: 'applicantSkillsVerificationMethods.languages',
};

const getUniqueMethods = (methods: ApplicantSkillsVerificationMethod[]) => {
    const map: { [key: string]: boolean } = {};

    return methods.filter((method) => {
        if (map[getTagName(method)]) {
            return false;
        }
        map[getTagName(method)] = true;
        return method;
    });
};

const ApplicantSkillsVerificationMethodsCard: TranslatedComponent<ApplicantSkillsVerificationMethodsCardProps> = ({
    applicantSkillsVerificationMethods: applicantMethods,
    applicantSkillsVerificationExpiring,
    trls,
}) => {
    const hhtmSource = useSelector(({ analyticsParams }) => analyticsParams.hhtmSource) as HhtmSource;
    const [isShown, setShown] = useState(false);
    const expirationDates = useMemo(
        () =>
            applicantSkillsVerificationExpiring.reduce<string[]>((acc, item) => {
                if (item.validity[ApplicantSkillsVerificationMethodKeys.ValidUntil]) {
                    acc.push(item.validity[ApplicantSkillsVerificationMethodKeys.ValidUntil]);
                }
                return acc;
            }, []),
        [applicantSkillsVerificationExpiring]
    );
    const applicantSkillsVerificationMethods = getUniqueMethods(applicantMethods);
    const [isShownLanguages, setShownLanguages] = useState(false);
    const methodsWithoutLanguages = applicantSkillsVerificationMethods.filter(
        (method) => method.verification_objects?.[0]?.category === 'SKILL'
    );
    const methodsLanguages = applicantSkillsVerificationMethods.filter(
        (method) => method.verification_objects?.[0]?.category === 'LANG'
    );
    const shortList = methodsWithoutLanguages.slice(NUMBER_METHODS.MIN_SHOWN_METHODS, NUMBER_METHODS.MAX_SHOWN_METHODS);
    const shortListLanguages = methodsLanguages.slice(
        NUMBER_METHODS.MIN_SHOWN_METHODS,
        NUMBER_METHODS.MAX_SHOWN_METHODS
    );
    const handleShownLanguages = (value: boolean) => setShownLanguages(value);
    const handleClickViewAllLanguages = () => {
        verificationKeySkillViewAllButtonClick({ hhtmSource });
        handleShownLanguages(true);
    };
    const languages = isShownLanguages ? methodsLanguages : shortListLanguages;
    const analyticsObject = {
        hhtmSource,
    };

    const handleShown = (value: boolean) => {
        setShown(value);
    };

    const handleClickViewAll = () => {
        verificationKeySkillViewAllButtonClick({ hhtmSource });
        handleShown(true);
    };

    const { applicantSkillsVerificationMethodModalProps, openApplicantSkillsVerificationMethodModal } =
        useApplicantSkillsVerificationMethodModal();

    const handleMethodCardClick = useCallback(
        (method: ApplicantSkillsVerificationMethod) => {
            openApplicantSkillsVerificationMethodModal({ method });
        },
        [openApplicantSkillsVerificationMethodModal]
    );

    const methods = [...(isShown ? methodsWithoutLanguages : shortList), ...applicantSkillsVerificationExpiring];

    const { createSkillVerificationMethodsPath } = useSkillVerificationMethodsBreadcrumbs();

    return (
        <ElementShownAnchor fn={verificationKeySkillBannerElementShown} {...analyticsObject}>
            <div className="applicant-resumes-card info-block noprint">
                <H3Section>
                    <span>{trls[TrlKeys.title]}</span>
                </H3Section>
                <VSpacing base={1} />
                <Text importance={TextImportance.Secondary}>{trls[TrlKeys.subTitleNotification]}</Text>
                <VSpacing base={4} />
                <OutdatedSkill expirationDates={expirationDates} multi />
                <VSpacing base={3} />
                {methods.length ? (
                    <div>
                        <Text strong>{trls[TrlKeys.keySkills]}</Text>
                        <VSpacing base={2} />
                        <div className="resume-key-skills-verification-methods">
                            {methods.map((method) => (
                                <div
                                    key={method.id}
                                    data-qa={method.id.toString()}
                                    className="resume-key-skills-verification-flex"
                                >
                                    <ApplicantSkillsVerificationMethodsCardButton
                                        method={method}
                                        onClick={handleMethodCardClick}
                                    />
                                </div>
                            ))}
                            {!isShown && methodsWithoutLanguages.length > NUMBER_METHODS.MAX_SHOWN_METHODS && (
                                <BlokoLink Element={Button} onClick={handleClickViewAll}>
                                    {`${trls[TrlKeys.showAll]} ${methodsWithoutLanguages.length}`}
                                    <VSpacing base={2} />
                                </BlokoLink>
                            )}
                            {isShown && (
                                <BlokoLink Element={Button} onClick={() => handleShown(false)}>
                                    {trls[TrlKeys.hide]}
                                </BlokoLink>
                            )}
                        </div>
                    </div>
                ) : null}
                {languages.length ? (
                    <div>
                        <VSpacing base={3} />
                        <Text strong>{trls[TrlKeys.languages]}</Text>
                        <VSpacing base={2} />
                        <div className="resume-key-skills-verification-methods">
                            {languages.map((method) => (
                                <div
                                    key={method.id}
                                    data-qa={method.id.toString()}
                                    className="resume-key-skills-verification-flex"
                                >
                                    <ApplicantSkillsVerificationMethodsCardButton
                                        method={method}
                                        onClick={handleMethodCardClick}
                                    />
                                </div>
                            ))}
                            {!isShownLanguages && methodsLanguages.length > NUMBER_METHODS.MAX_SHOWN_METHODS && (
                                <BlokoLink Element={Button} onClick={handleClickViewAllLanguages}>
                                    {`${trls[TrlKeys.showAll]} ${methodsLanguages.length}`}
                                    <VSpacing base={2} />
                                </BlokoLink>
                            )}
                            {isShownLanguages && (
                                <BlokoLink Element={Button} onClick={() => handleShownLanguages(false)}>
                                    {trls[TrlKeys.hide]}
                                </BlokoLink>
                            )}
                        </div>
                    </div>
                ) : null}
                <VSpacing base={5} />
                <div className="resume-key-skills-verification-button-container">
                    <BlokoLink Element={Link} to={createSkillVerificationMethodsPath()}>
                        <Button
                            stretched
                            kind={ButtonKind.Primary}
                            scale={ButtonScale.Small}
                            appearance={ButtonAppearance.Filled}
                            onClick={() => verificationKeySkillToTestButtonClick({ hhtmSource })}
                        >
                            {trls[TrlKeys.link]}
                        </Button>
                    </BlokoLink>
                </div>
            </div>
            <ApplicantSkillsVerificationMethodModal {...applicantSkillsVerificationMethodModalProps} />
        </ElementShownAnchor>
    );
};

export default translation(ApplicantSkillsVerificationMethodsCard);
