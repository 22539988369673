import React, { useState, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';

import {
    ChevronScaleSmallKindRight,
    ChevronScaleSmallKindLeft,
    IconDynamic,
    IconLink,
    IconColor,
} from 'bloko/blocks/icon';
import debounce from 'bloko/common/debounce';
import useResize from 'bloko/common/hooks/useResize';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { PaymentServicesFromBackend } from 'lux/models/applicantPaymentServices';
import translation from 'src/components/translation';
import { useIsRussia } from 'src/hooks/useCountries';
import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useIsHhru } from 'src/hooks/useSites';

import Card from 'src/components/ApplicantServicesSlider/Card';

const DEFAULT_SCROLL_STEP = 275;
const TRACKING_INTERVAL_MS = 300;

const TrlKeys = {
    completeResumeTitle: 'applicantServicesSlider.cards.completeResume.title',
    completeResumeText: 'applicantServicesSlider.cards.completeResume.text',
    interviewTitle: 'applicantServicesSlider.cards.interview.title',
    interviewText: 'applicantServicesSlider.cards.interview.text',
    consultationTitle: 'applicantServicesSlider.cards.consultation.title',
    consultationText: 'applicantServicesSlider.cards.consultation.text',
    extraBannerTitle: 'applicantServicesSlider.cards.extraBanner.title',
    extraBannerText: 'applicantServicesSlider.cards.extraBanner.text',
};

const ApplicantServicesSlider: TranslatedComponent = ({ trls }) => {
    const scrollboxRef = useRef<HTMLDivElement>(null);
    const sliderRef = useRef<HTMLDivElement>(null);
    const [leftControlVisible, setLeftControlVisible] = useState(false);
    const [rightControlVisible, setRightControlVisible] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const isRussia = useIsRussia();
    const isHHRu = useIsHhru();
    const isHeadHunterPlatform = useIsHeadHunterPlatform();

    const recalculateControls: () => void = useCallback(() => {
        if (!scrollboxRef.current || !sliderRef.current) {
            return;
        }

        const sliderWidth = scrollboxRef.current.scrollWidth;
        const wrapperWidth = scrollboxRef.current.getBoundingClientRect().width;

        if (wrapperWidth >= sliderWidth) {
            setLeftControlVisible(false);
            setRightControlVisible(false);
            return;
        }

        if (sliderWidth - scrollPosition < wrapperWidth) {
            setScrollPosition(sliderWidth - wrapperWidth);
        }

        if (scrollPosition < 0) {
            setScrollPosition(0);
        }

        setLeftControlVisible(scrollPosition > 0);
        setRightControlVisible(sliderWidth - scrollPosition > wrapperWidth);
    }, [scrollboxRef, sliderRef, scrollPosition]);

    useResize(debounce(recalculateControls, TRACKING_INTERVAL_MS));

    useEffect(() => {
        recalculateControls();
    }, [recalculateControls]);

    useEffect(() => {
        if (!scrollboxRef.current) {
            return;
        }

        scrollboxRef.current.scrollTo({ top: 0, left: scrollPosition, behavior: 'smooth' });
    }, [scrollboxRef, scrollPosition]);

    const changeMargin = useCallback(
        (delta: number) => {
            setScrollPosition(scrollPosition + delta);
        },
        [scrollPosition]
    );

    const topLevelDomain = useSelector((state) => state.topLevelDomain);
    const applicantPaymentServices = useSelector((state) => state.applicantPaymentServices);

    const listServices = [];

    const resumeExtraBannerLink = useSelector((state) => state.resumeExtraBannerLink);

    if (isHeadHunterPlatform) {
        listServices.push(
            {
                title: trls[TrlKeys.extraBannerTitle],
                text: trls[TrlKeys.extraBannerText],
                classname: 'applicant-services-slider-card-image_extra-banner',
                link: resumeExtraBannerLink,
                dataAnalytics: 'resume-extra-banner',
                enabled: !!resumeExtraBannerLink && isRussia && isHHRu,
            },
            {
                title: trls[TrlKeys.completeResumeTitle],
                text: format(trls[TrlKeys.completeResumeText], {
                    '{0}': String(topLevelDomain),
                }),
                classname: 'applicant-services-slider-card-image_complete-resume',
                link: '/article/expert_resume',
                dataAnalytics: 'complete_resume',
                enabled: applicantPaymentServices.includes(PaymentServicesFromBackend.CompleteResume),
            },
            {
                title: trls[TrlKeys.interviewTitle],
                text: trls[TrlKeys.interviewText],
                classname: 'applicant-services-slider-card-image_interview',
                link: '/interview_practice',
                dataAnalytics: 'interview_practice',
                enabled: applicantPaymentServices.includes(PaymentServicesFromBackend.CompleteResumeInterviewPractice),
            },
            {
                title: trls[TrlKeys.consultationTitle],
                text: trls[TrlKeys.consultationText],
                classname: 'applicant-services-slider-card-image_consultation',
                link: '/career_consult',
                dataAnalytics: 'career_consult',
                enabled: applicantPaymentServices.includes(PaymentServicesFromBackend.CompleteResumeConsultOnly),
            }
        );
    }
    const applicantServices = listServices.filter((service) => service.enabled);

    return applicantServices.length ? (
        <div className="applicant-services-slider-container applicant-services-slider-container_mb-small">
            <div className="applicant-services-slider-wrapper">
                <div
                    onClick={() => changeMargin(-DEFAULT_SCROLL_STEP)}
                    className={classnames('applicant-services-slider-control-left', {
                        'applicant-services-slider-control-left_shown': leftControlVisible,
                    })}
                >
                    <IconDynamic>
                        <IconLink Element="button" type="button">
                            <ChevronScaleSmallKindLeft initial={IconColor.Gray50} highlighted={IconColor.Blue60} />
                        </IconLink>
                    </IconDynamic>
                </div>
                <div
                    onClick={() => changeMargin(DEFAULT_SCROLL_STEP)}
                    className={classnames('applicant-services-slider-control-right', {
                        'applicant-services-slider-control-right_shown': rightControlVisible,
                    })}
                >
                    <IconDynamic>
                        <IconLink Element="button" type="button">
                            <ChevronScaleSmallKindRight initial={IconColor.Gray50} highlighted={IconColor.Blue60} />
                        </IconLink>
                    </IconDynamic>
                </div>
                <div
                    ref={scrollboxRef}
                    className={classnames('applicant-services-slider-scrollbox', {
                        'applicant-services-slider-scrollbox_visible-right-control': rightControlVisible,
                        'applicant-services-slider-scrollbox_visible-left-control': leftControlVisible,
                    })}
                >
                    <div ref={sliderRef} className="applicant-services-slider">
                        {applicantServices.map((service, index) => (
                            <Card
                                key={index}
                                title={service.title}
                                text={service.text}
                                classname={service.classname}
                                link={service.link}
                                dataAnalytics={service.dataAnalytics}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default translation(ApplicantServicesSlider);
