import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import verificationKeySkillLearnButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/verification_key_skill/verification_key_skill_learn_button_click';
import verificationKeySkillResultNextButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/verification_key_skill/verification_key_skill_result_next_button_click';
import verificationKeySkillResultScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/verification_key_skill/verification_key_skill_result_screen_shown';
import verificationKeySkillVacancyButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/verification_key_skill/verification_key_skill_vacancy_button_click';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import BlokoLink from 'bloko/blocks/link';
import Modal, { ModalHeader, ModalTitle, ModalFooter, ModalContent } from 'bloko/blocks/modal';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import getConversionType from 'bloko/common/getConversionType';
import { LangTrls, TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import {
    ApplicantSkillsVerificationResult,
    ApplicantSkillsVerificationResultKeys,
    refreshApplicantSkillsVerificationResults,
} from 'lux/models/applicantSkillsVerificationResult';
import { CareerLinksKeys, CareerPlatformState } from 'lux/models/careerPlatformState';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import TestRank from 'src/components/TestCardRank/TestRank';

interface TestFinishModalProps {
    rank?: number | null;
    success?: boolean;
    name?: string;
    education?: boolean;
    correctAnswers: number;
    questionsAmount: number;
    educationUrl: string;
    results: ApplicantSkillsVerificationResult;
    isSkill: boolean;
    sourceName?: string;
}

const TrlKeys = {
    0: '',
    3: 'tests.card.level.advanced',
    2: 'tests.card.level.middle',
    1: 'tests.card.level.beginner',
    correctAnswers: 'tests.card.finish.correct.answers',
    buttonClose: 'tests.button.close',
    buttonEducation: 'tests.button.education',
    select: {
        one: 'tests.card.finish.one',
        some: 'tests.card.finish.some',
        many: 'tests.card.finish.many',
    },
    skillLevelSuccess: 'tests.skill.test.success',
    skillLevelFailure: 'tests.skill.test.failure',
    langLevelSuccess: 'tests.language.test.success',
    langLevelFailure: 'tests.language.test.failure',
    careerFail: 'careerPlatformBanner.verification.fail',
    careerSuccess: 'careerPlatformBanner.verification.success',
    verificationFail: 'skillVerificationResults.fail',
    verificationSuccess: 'skillVerificationResults.success',
};

const getCareerLink = (
    trls: LangTrls,
    success: TestFinishModalProps['success'],
    careerPlatformLinks: CareerPlatformState | null,
    onClick: () => void
) => {
    if (!careerPlatformLinks?.links) {
        return null;
    }

    return (
        <FormSpacer>
            <BlokoLink
                data-qa="go-to-career"
                Element={Link}
                target={'_blank'}
                to={careerPlatformLinks.links[CareerLinksKeys.Courses]}
            >
                <Button kind={ButtonKind.Primary} appearance={ButtonAppearance.Filled} onClick={onClick}>
                    {trls[success ? TrlKeys.careerSuccess : TrlKeys.careerFail]}
                </Button>
            </BlokoLink>
        </FormSpacer>
    );
};

const TestFinishModal: TranslatedComponent<TestFinishModalProps> = ({
    trls,
    rank = 2,
    success = false,
    name = '',
    education,
    correctAnswers,
    questionsAmount,
    educationUrl,
    results,
    isSkill,
    sourceName,
}) => {
    const careerLinks = useSelector((state) =>
        success ? state.careerPlatformLinks : state.careerPlatformLinksForSkill
    );
    const showCareerBanner = Boolean(!education && careerLinks && careerLinks.links);
    const [isVisible, setVisible] = useState(true);
    const dispatch = useDispatch();
    const testResultAnalyticsObj = {
        verificationTestName: name,
        resultType: success ? 1 : 0,
        isBrand: results.method[ApplicantSkillsVerificationResultKeys.BrandingSettings].enabled,
        isPaidClick:
            results.method[ApplicantSkillsVerificationResultKeys.BrandingSettings][
                ApplicantSkillsVerificationResultKeys.CtaButtonEnabled
            ],
        nameBrand: results.method.source.name,
        verificationMethodId: results.method.id,
        verificationSourceId: results.method.source.id,
        levelId: rank || 2,
    };

    useEffect(() => {
        if (rank) {
            verificationKeySkillResultScreenShown(testResultAnalyticsObj);
        }
    }, [rank, testResultAnalyticsObj]);
    if (!rank) {
        return <></>;
    }

    let headerText = format(success ? trls[TrlKeys.skillLevelSuccess] : trls[TrlKeys.skillLevelFailure], {
        '{0}': isSkill ? trls[TrlKeys[rank as keyof typeof TrlKeys] as string].toLowerCase() : '',
    });
    if (!isSkill) {
        headerText = success ? trls[TrlKeys.langLevelSuccess] : trls[TrlKeys.langLevelFailure];
    }

    const conversionTrl = getConversionType({
        one: trls[TrlKeys.select.one],
        some: trls[TrlKeys.select.some],
        many: trls[TrlKeys.select.many],
        value: correctAnswers,
    });
    const correctAnswersText = format(trls[TrlKeys.correctAnswers], {
        '{0}': correctAnswers,
        '{1}': conversionTrl,
        '{2}': questionsAmount,
    });

    const textByResult = success ? TrlKeys.verificationSuccess : TrlKeys.verificationFail;
    const defaultText = sourceName ? format(trls[textByResult], { '{name}': sourceName }) : '';

    const handleClose = () => {
        setVisible(false);
        dispatch(refreshApplicantSkillsVerificationResults(null));
    };

    const handleClickGoLearn = () => {
        verificationKeySkillLearnButtonClick(testResultAnalyticsObj);
        verificationKeySkillResultNextButtonClick({ type: 'learn', ...testResultAnalyticsObj });
        handleClose();
    };

    const handleCloseModal = () => {
        handleClose();
        verificationKeySkillVacancyButtonClick(testResultAnalyticsObj);
        verificationKeySkillResultNextButtonClick({ type: 'close', ...testResultAnalyticsObj });
        dispatch(push('applicant/resumes'));
    };

    const handleClickCareerLink = () => {
        verificationKeySkillResultNextButtonClick({
            type: success ? 'learn_new' : 'look_at_courses',
            ...testResultAnalyticsObj,
        });
        handleClose();
    };

    return (
        <Modal visible={isVisible} onClose={handleCloseModal}>
            <ModalHeader>
                <ModalTitle Element="h2">{headerText}</ModalTitle>
            </ModalHeader>
            <ModalContent>
                <div className="skills-verification-methods-finish-modal">
                    <TestRank icon={isSkill} rank={rank} background success={success} name={name} />
                    <VSpacing base={3} />
                    <Text importance={TextImportance.Tertiary} data-qa={correctAnswers.toString()}>
                        {correctAnswersText}
                    </Text>
                    <VSpacing base={5} />
                    <Text>{results.items?.[0]?.content || defaultText}</Text>
                </div>
            </ModalContent>
            <ModalFooter>
                <FormSpacer>
                    <Button data-qa="go-to-vacancies" onClick={handleCloseModal} appearance={ButtonAppearance.Outlined}>
                        {trls[TrlKeys.buttonClose]}
                    </Button>
                </FormSpacer>
                {education ? (
                    <FormSpacer>
                        <BlokoLink Element={Link} to={educationUrl}>
                            <Button
                                data-qa="close"
                                kind={ButtonKind.Primary}
                                appearance={ButtonAppearance.Filled}
                                onClick={handleClickGoLearn}
                            >
                                {trls[TrlKeys.buttonEducation]}
                            </Button>
                        </BlokoLink>
                    </FormSpacer>
                ) : (
                    showCareerBanner && getCareerLink(trls, success, careerLinks, handleClickCareerLink)
                )}
            </ModalFooter>
        </Modal>
    );
};

export default translation(TestFinishModal);
