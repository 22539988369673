/* eslint-disable import/prefer-default-export */
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import resumeRenewCompleteExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_renew_complete';
import resumeActionLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_action_link_button_click';
import resumeUpdateSuccessScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_update_success_screen_shown';
import { Card, Title, Text, useBreakpoint, VSpacingContainer } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';
import { formatToReactComponent } from 'bloko/common/trl';

import { resumesSetToUpdateValueAction, ApplicantResumeItem } from 'lux/models/applicantResumes';
import HumanDate from 'src/components/HumanDate';
import translation from 'src/components/translation';
import { useAddEventPixelsRu } from 'src/hooks/useAddEventPixelsRu';
import { useSelector } from 'src/hooks/useSelector';
import { BotUpdateModal } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/BotUpdateModal';
import { PreparingByExpertCard } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/PreparingByExpertCard';
import { ResumeActions } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeActions';
import { MobileActionsMenu } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeActions/ActionsMenu/MobileActionsMenu';
import { ResumeButtons } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeButtons';
import { ResumeDeleteModal } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeDeleteModal';
import { DeleteModalProvider } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeDeleteModal/DeleteModalProvider';
import { ResumeRecommendations } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeRecommendations';
import { ResumeStatistics } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeStatistics';
import { BotUpdateResumeContext } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/context/botUpdateResume';
import { usePreparingByExpert } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/hooks/usePreparingByExpert';
import { BLOCKED, NOT_FINISHED } from 'src/utils/constants/resumeStatuses';

import styles from './styles.less';

const Features = {
    successResumeUpdateModalTimeout: 'success_resume_update_modal_timeout_h',
};

const LAST_SHOW_SUCCESS_UPDATE_MODAL_KEY = 'last_show_success_update_modal';

const TrlKeys = {
    updated: 'resumeList.updated.label',
    emptyTitle: 'resume.title.empty',
    blockedText: 'resumeList.status.blocked.text',
    updatedManual: 'resumeList.updated.label.manual',
    notFinishedText: 'resumeList.status.notFinished.text',
    isPreparingByExpert: 'resumeList.resume.status.isPreparingByExpert',
};

export const ResumeCard = translation<{ resume: ApplicantResumeItem }>(({ resume, trls }) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();
    const addEventPixelsRu = useAddEventPixelsRu();

    const { _attributes, title, toUpdate, renewalTime } = resume;
    const resumeId = parseInt(_attributes.id, 10);

    const isPreparingByExpert = usePreparingByExpert(_attributes.tags);
    const isUnpubslished = [NOT_FINISHED, BLOCKED].includes(_attributes.status);
    const nearestIntervalStartTime = renewalTime?.nearestIntervalStartTime;

    const { recommendation, recommendationDetails } = useSelector(
        ({ applicantResumesStatistics }) => applicantResumesStatistics?.resumes?.[_attributes.id] || {}
    );

    const [updated, setUpdated] = useState<null | number>(null);
    const [timeUntilUpdate, setTimeUntilUpdate] = useState<null | number>(null);
    const [currentTimeLeft, setCurrentTimeLeft] = useState(toUpdate.value * 1000);
    const [isAutoRenewalRecommended, setIsAutoRenewalRecommended] = useState(
        toUpdate.value > 0 && !nearestIntervalStartTime
    );
    const [successUpdateModalVisible, setSuccessUpdateModalVisible] = useState(false);
    const [successUpdate, setSuccessUpdate] = useState(false);

    const chatBotHref = useSelector((state) => state.chatBot?.href || '');
    const successUpdateModalTimeout = useSelector(
        (state) => state.features[Features.successResumeUpdateModalTimeout] as number
    );
    const canUpdateResumeByBot = Boolean(chatBotHref);

    useEffect(() => {
        setUpdated(_attributes.updated);
        setTimeUntilUpdate(new Date().getTime() + toUpdate.value * 1000);
    }, [_attributes.updated, toUpdate.value]);

    // без useCallback будет неправильно работать TouchModule в ResumeUpdate
    const onUpdate = useCallback(
        (updated: number) => {
            setUpdated(updated);
            dispatch(
                resumesSetToUpdateValueAction({ hash: _attributes.hash, value: _attributes.update_timeout / 1000 })
            );
            setIsAutoRenewalRecommended(!nearestIntervalStartTime);
            setSuccessUpdate(true);

            resumeRenewCompleteExternal();
            addEventPixelsRu('B2C_RESUME_UPDATE');
        },
        [dispatch, _attributes.hash, _attributes.update_timeout, nearestIntervalStartTime, addEventPixelsRu]
    );

    // без useCallback будет неправильно работать TouchModule в ResumeUpdate
    const onTimeChanged = useCallback((currentTimeLeft: number) => {
        setCurrentTimeLeft(currentTimeLeft);
        if (currentTimeLeft === 0) {
            setIsAutoRenewalRecommended(false);
        }
    }, []);

    const closeSuccessUpdateModal = useCallback(() => {
        setSuccessUpdateModalVisible(false);

        Analytics.sendEvent('applicant', 'resume_renew_modal', 'close');
    }, []);

    const openSuccessUpdateModal = useCallback(() => {
        setSuccessUpdateModalVisible(true);

        LocalStorageWrapper.setItem(LAST_SHOW_SUCCESS_UPDATE_MODAL_KEY, Date.now().toString());

        Analytics.sendEvent('applicant', 'resume_renew_modal', 'opened');
        resumeUpdateSuccessScreenShown({ resumeId });
    }, [resumeId]);

    const botUpdateResumeContextValue = useMemo(
        () => ({
            modalVisible: successUpdateModalVisible,
            handleCloseModal: closeSuccessUpdateModal,
            resumeHash: _attributes.hash,
            resumeId,
            successUpdate,
            recommendationDetails,
            chatBotHref,
            canUpdateResumeByBot,
        }),
        [
            closeSuccessUpdateModal,
            successUpdateModalVisible,
            _attributes.hash,
            resumeId,
            successUpdate,
            recommendationDetails,
            chatBotHref,
            canUpdateResumeByBot,
        ]
    );

    useEffect(() => {
        const lastShowModal = Number(LocalStorageWrapper.getItem(LAST_SHOW_SUCCESS_UPDATE_MODAL_KEY));
        const now = Date.now();
        const successUpdateModalTimeoutMs = successUpdateModalTimeout * 60 * 60 * 1000;
        const timeoutPassed = now - lastShowModal > successUpdateModalTimeoutMs;

        if (canUpdateResumeByBot && successUpdate && timeoutPassed) {
            openSuccessUpdateModal();
        }
    }, [successUpdate, openSuccessUpdateModal, canUpdateResumeByBot, successUpdateModalTimeout]);

    const resumeTitle = title[0] ? title[0].string : trls[TrlKeys.emptyTitle];

    const resumeLink =
        _attributes.isSearchable || _attributes.status === BLOCKED
            ? `/resume/${_attributes.hash}`
            : `/applicant/resumes/short?resume=${_attributes.hash}`;

    const handleTitleClick = useCallback(() => {
        resumeActionLinkButtonClick({ buttonName: 'resume_title', resumeId });
    }, [resumeId]);

    if (isPreparingByExpert) {
        return <PreparingByExpertCard id={_attributes.id} title={resumeTitle} />;
    }

    const description =
        currentTimeLeft > 0 && !renewalTime?.nearestIntervalStartTime
            ? formatToReactComponent(trls[TrlKeys.updatedManual], {
                  '{0}': (
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      <HumanDate date={timeUntilUpdate} showClock textify preposition />
                  ),
              })
            : formatToReactComponent(trls[TrlKeys.updated], {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  '{0}': <HumanDate date={updated} showClock textify preposition />,
              });

    return (
        <BotUpdateResumeContext.Provider value={botUpdateResumeContextValue}>
            <DeleteModalProvider>
                <Card
                    showBorder
                    stretched
                    borderRadius={24}
                    data-qa={`resume${_attributes.marked ? ' resume-highlighted' : ''}`}
                    data-qa-id={_attributes.id}
                    data-qa-title={resumeTitle}
                >
                    <VSpacingContainer default={16}>
                        <div className={styles.headerWrapper}>
                            <Card
                                Element={Link}
                                stretched
                                actionCard
                                borderRadius={24}
                                onClick={handleTitleClick}
                                paddingTop={24}
                                paddingLeft={24}
                                paddingRight={24}
                                to={resumeLink}
                            >
                                <VSpacingContainer default={16}>
                                    <div className={styles.title}>
                                        <Title
                                            data-qa="resume-title"
                                            Element="h3"
                                            size={isMobile ? 'extra-small' : 'medium'}
                                            style={isUnpubslished ? 'secondary' : 'primary'}
                                            description={!isUnpubslished ? description : null}
                                        >
                                            {title[0] ? title[0].string : trls[TrlKeys.emptyTitle]}
                                        </Title>
                                    </div>

                                    {_attributes.status === NOT_FINISHED && (
                                        <Text typography="label-2-regular">{trls[TrlKeys.notFinishedText]}</Text>
                                    )}

                                    {_attributes.status === BLOCKED && (
                                        <VSpacingContainer default={4}>
                                            <Text typography="label-2-regular">{trls[TrlKeys.blockedText]}</Text>
                                        </VSpacingContainer>
                                    )}
                                </VSpacingContainer>
                            </Card>

                            {isMobile && <MobileActionsMenu resume={resume} />}
                        </div>

                        {!isUnpubslished && (
                            <div className={styles.statistics}>
                                <ResumeStatistics hash={_attributes.hash} resumeId={resumeId} />
                            </div>
                        )}

                        <div className={styles.footer}>
                            <div className={styles.footerButtons}>
                                <ResumeButtons
                                    resume={resume}
                                    onUpdate={onUpdate}
                                    onTimeChanged={onTimeChanged}
                                    isAutoRenewalRecommended={isAutoRenewalRecommended}
                                />

                                <ResumeActions
                                    onUpdate={onUpdate}
                                    resume={resume}
                                    onTimeChanged={onTimeChanged}
                                    isAutoRenewalRecommended={isAutoRenewalRecommended}
                                />
                            </div>

                            <ResumeRecommendations
                                resume={resume}
                                recommendation={recommendation}
                                recommendationDetails={recommendationDetails}
                            />

                            <BotUpdateModal />
                            <ResumeDeleteModal />
                        </div>
                    </VSpacingContainer>
                </Card>
            </DeleteModalProvider>
        </BotUpdateResumeContext.Provider>
    );
});
